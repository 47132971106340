/* eslint-disable */

<template>
  <div class="vx-row Lgx202142297964412928">
    <div class="vx-col w-full">
      <vx-card class="mb-base">
        <div class="fourth-sim pt-0">
          <div class="vx-row mb-4">
            <div class="vx-col w-3/12 flex items-center">
              <h2 class="font-bold">Name</h2>
            </div>
            <div class="vx-col w-3/12 flex items-center">
              <h2 class="font-bold">Variable Type</h2>
            </div>
            <div class="vx-col w-3/12 flex items-center">
              <h2 class="font-bold">Scope</h2>
            </div>
            <div class="vx-col w-3/12 flex items-center">
              <h2 class="font-bold">Default</h2>
            </div>
          </div>
          <div class="vx-row items-center border-white border-2 border-solid rounded p-2 my-4" v-for="item in table_items" :key="item.id">
            <div class="vx-col w-3/12 flex items-center p-3">
              <h2 class="">{{ item.name }}</h2>
            </div>
            <div class="vx-col w-3/12 p-3">
              <v-select
                placeholder="Select an attack from the dropdown"
                class="w-full"
                :options="variable_options"
                v-model="item.variable"
                :disabled="item.variable_disabled"
              />
              <small v-if="item.variable_is_correct !== null && item.variable_is_correct === false" class="ml-1 text-danger font-semibold"
                >This answer is invalid</small
              >
              <small v-if="item.variable_is_correct !== null && item.variable_is_correct === true" class="ml-1 text-success font-semibold"
                >This answer is correct</small
              >
            </div>
            <div class="vx-col w-3/12 p-3">
              <vs-input v-model="item.scope" class="w-full" readonly="true" />
              <small v-if="item.scope_is_correct !== null && item.scope_is_correct === false" class="ml-1 text-danger font-semibold"
                >This answer is invalid</small
              >
              <small v-if="item.scope_is_correct !== null && item.scope_is_correct === true" class="ml-1 text-success font-semibold"
                >This answer is correct</small
              >
            </div>
            <div class="vx-col w-3/12 p-3">
              <vs-input v-model="item.default" class="w-full" readonly="true" />
              <small v-if="item.default_is_correct !== null && item.default_is_correct === false" class="ml-1 text-danger font-semibold"
                >This answer is invalid</small
              >
              <small v-if="item.default_is_correct !== null && item.default_is_correct === true" class="ml-1 text-success font-semibold"
                >This answer is correct</small
              >
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Prism from 'vue-prism-component';
import shuffle from 'shuffle-array';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      update_index: 0,

      table_items: [
        {
          id: 1,
          name: 'TransactionItem',
          variable: '',
          variable_answer: 'String',
          variable_is_correct: null,
          variable_disabled: false,
          scope: 'General Business …',
          scope_answer: 'General Business …',
          scope_is_correct: null,
          default: 'Enter a VB expression',
          default_answer: 'Enter a VB expression',
          default_is_correct: null,
        },
        {
          id: 2,
          name: 'SystemException',
          variable: 'Exception',
          variable_answer: 'Exception',
          variable_is_correct: null,
          variable_disabled: true,
          scope: 'General Business …',
          scope_answer: 'General Business …',
          scope_is_correct: null,
          default: 'Enter a VB expression',
          default_answer: 'Enter a VB expression',
          default_is_correct: null,
        },
        {
          id: 3,
          name: 'BusinessException',
          variable: 'BusinessRuleException',
          variable_answer: 'BusinessRuleException',
          variable_is_correct: null,
          variable_disabled: true,
          scope: 'General Business …',
          scope_answer: 'General Business …',
          scope_is_correct: null,
          default: 'Enter a VB expression',
          default_answer: 'Enter a VB expression',
          default_is_correct: null,
        },
        {
          id: 4,
          name: 'TransactionNumber',
          variable: 'Int32',
          variable_answer: 'Int32',
          variable_is_correct: null,
          variable_disabled: true,
          scope: 'General Business …',
          scope_answer: 'General Business …',
          scope_is_correct: null,
          default: '1',
          default_answer: '1',
          default_is_correct: null,
        },
        {
          id: 5,
          name: 'Config',
          variable: 'Dictionary<String,Obj>',
          variable_answer: 'Dictionary<String,Obj>',
          variable_is_correct: null,
          variable_disabled: true,
          scope: 'General Business …',
          scope_answer: 'General Business …',
          scope_is_correct: null,
          default: 'Enter a VB expression',
          default_answer: 'Enter a VB expression',
          default_is_correct: null,
        },
      ],

      variable_options: ['String', 'List<String>', 'QueueItem', 'DataRow'],
    };
  },
  methods: {
    markSimulation() {
      let totalScore = 0;

      this.table_items.forEach((x, i) => {
        if (x.variable && x.variable === x.variable_answer) {
          this.table_items[i].variable_is_correct = true;
          totalScore++;
        } else {
          this.table_items[i].variable_is_correct = false;
        }

        if (x.scope && x.scope === x.scope_answer) {
          this.table_items[i].scope_is_correct = true;
          totalScore++;
        } else {
          this.table_items[i].scope_is_correct = false;
        }

        if (x.default && x.default === x.default_answer) {
          this.table_items[i].default_is_correct = true;
          totalScore++;
        } else {
          this.table_items[i].default_is_correct = false;
        }
      });

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    vSelect,
    shuffle,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style>
.Lgx202142297964412928 {
  font-size: 19px;
}
</style>
